<app-nav-bar></app-nav-bar>

<div id="colorlib-main">
  <section class="colorlib-about" data-section="about">
      <div class="colorlib-narrow-content">
          <div class="row">
              <div class="col-md-12">
                  <div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                      <div class="col-md-12">
                          <div class="about-desc">
                              <h2>Elvis Shrestha</h2>
                              <hr>
                              <p>
                                  I'm a software <a href="https://github.com/shreelvi"><strong>engineer</strong></a> with several years of working experience in different tech stack including java -spring, spring boot, c# - asp.net and some core python.
                                  
                                  I have worked on various small and large scale development project and worked on distributed technologies, and modern technologies like designing and developing REST APIs, NoSQL databases, Agile development, and cloud environment with automation.
                                
                                  As a professional engineer, I have also contributed on developing both internal and external system from the beginning and provide support & enhancements.
                                 
                              </p>
                             
                                   
                 
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      
                      <div class="col-md-3 animate-box" data-animate-effect="fadeInRight">
                          <a href="https://resnology-core-sql.azurewebsites.net/" target="_blank">
                              <div class="services color-2">
                                  <span class="icon2"><i class="fa fa-code"></i></span>
                                  <h3>Web App<br> Developments</h3>
                              </div>
                          </a>
                      </div>
                      

                      <div class="col-md-3 animate-box" data-animate-effect="fadeInRight">
                          <a href="https://shreelvi.github.io/my-blogs" target="_blank">
                              <div class="services color-3">
                                  <span class="icon2"><i class="fa fa-pencil"></i></span>
                                  <h3>Technical Writing/ Blogs</h3>
                              </div>
                          </a>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </section>

  
  <section class="colorlib-experience" data-section="experience">
      <div class="colorlib-narrow-content">
          <div class="row">
              <div class="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                  <h2>Work Experiences</h2>
                  <hr>
              </div>
          </div>
        
        
         
                  
              
                      <article class="timeline-entry animate-box" data-animate-effect="fadeInRight">
                        <div class="timeline-centered">
                          <div class="timeline-entry-inner">
                                <div class="timeline-icon color-3">
                                  <i class="icon-world"></i>
                                </div>
                          
                            <div class="timeline-label">
                                <h2><a href="#" target="_blank">General Insurance</a></h2>
                                <ul>

                                  <li>Provided technical support for API and data products to both internal and external software developers</li>
                                  <li>Experience with directly supporting customers of software products</li>
                                  <li>Provided technical onboarding and support to 3rd party software platforms leveraging API and data products</li>
                                  <li>Worked with the API enablement development teams to continuously refine, optimize, and automate the API support process</li>

                                </ul>
                                <p> 
                            </div>
                       </div>
                          
                        
                        <div class="timeline-entry-inner">
                          <div class="timeline-icon color-3">
                              <i class="icon-world"></i>
                          </div>
                          
                          <div class="timeline-label">
                              <h2><a href="#" target="_blank">Mtari technologies/Charles schwab</a> </h2>
                              <ul>
                                <li>Worked as a software engineer involved in complete product development lifecycle to develop an internal application that integrated other remote apis and provided easier and fast access to those apis.</li>
                                <li>Tech stack used to develop -- Java- Springboot, spring data, spring security, Asp.net core 5 web api/ active directory authentication, mongoDb, elastic search(ELK), PCF cloud, Angular 10 and                                 Also worked on java/springboot project related to the app we were developing

                                </li>
                                <li>Configured servers for dev, qa and prod on pcf cloud, automated bitbucket using CICD bamboo and PCF</li>
                              
                              </ul>
                              <p> 
                          </div>
                       </div>
                          
                          <div class="timeline-entry-inner">
                          <div class="timeline-icon color-3">
                              <i class="icon-world"></i>
                          </div>
                          <div class="timeline-label">
                            <h2><a href="#" target="_blank">RCG global</a></h2>
                              <ul>
          
                                <li>
                                  designed and developed REST APIs for an internal system to keep track of order and inventories. 
                                </li>
                                <li>
                                  worked on SQL databases with ORM technologies for persisting data, wrote advanced sql queries and procedures & developed front-end systen with ReactJS and Node.js
                                </li>
                                <li>
                                  Implemented authentication using Asp.net identity, Azure Active directory to protect web apis and access as a client using SPA, Angular and debugged other issues.

                                </li>
                                <li>Deployed apps to azure cloud app service, worked on managing resources (storage, db servers), logging, authentication in the azure</li>
                      
                              </ul>
                              
    
                          </div>
                          
                         
                       </div>
                       
                          
  
  <section class="colorlib-contact" data-section="contact">
      <div class="colorlib-narrow-content">
          <div class="row">
              <div class="col-md-12">
                  <div class="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                      <h2>Contact</h2>
                      <hr>
                      <p>If you would like to get in touch with me, be it for discussing a business, or to just say hi, feel free to send me an email at <strong><a href="mailto: elvishrestha@gmail.com">elvishrestha@gmail.com</a></strong></p>
                      <a href="https://twitter.com/elvis_shrestha" class="fa fa-twitter" target="_blank"></a>
                      <a href="mailto:elvishrestha@gmail.com" class="fa fa-google" target="_blank"></a>
                  </div>
              </div>
          </div>
      </div>
  </section>


  


